<template>
  <Loader v-if="!$trip"/>
  <div class="trip-view"
       v-else>
    <div class="trip-view__wrapper">
      <Section class="trip-view__hero-billboard"
               limiter>
        <BreadCrumbs class="p-t-sm-20 p-t-md-20"
                     :params="{navigation, color: 'rgba(255,255,255,.5)'}"/>
        <h1 class="trip-view__full-info-title"
            v-if="$mobile">{{trip.title}}</h1>
        <Loader v-if="images && images.length === 0"/>
        <Slider :height="height"
                :items="images"
                :indentation="indentation"
                class="trip-view__slider"
                component="ImageComponent"
                :count="1"
                :limiter="$laptop"
                v-else/>
        <Row justify="between"
             :wrap="$mobile">
          <div :class="$mobile ? 'column__full' : ''">
            <Column :full="$mobile"
                    class="trip-view__short-info grid-md-4 grid-lg-4">
              <Row v-if="subtype"
                   class="trip-view__short-info-row trip-view__short-info-type">
                <img class="trip-view__short-info-icon"
                     :src="imageSubtype"/>
                <span class="trip-view__short-info-text">{{subtype}}</span>
              </Row>
              <Row v-if="duration"
                   class="trip-view__short-info-row">
                <Icon class="trip-view__short-info-icon"
                      viewport="0 0 20 20"
                      xlink="duration"/>
                <span class="trip-view__short-info-text">{{duration}}</span>
              </Row>
              <Row v-if="distance"
                   class="trip-view__short-info-row">
                <Icon class="trip-view__short-info-icon"
                      viewport="0 0 20 20"
                      xlink="route"/>
                <span class="trip-view__short-info-text">{{distance}}</span>
              </Row>
              <Row class="trip-view__short-info-row">
                <Icon class="trip-view__short-info-icon"
                      viewport="0 0 20 20"
                      xlink="complexity"/>
                <span class="trip-view__short-info-text">{{level}}</span>
              </Row>
            </Column>
          </div>
          <Column class="grid-md-4 grid-lg-8"
                  :full="$mobile">
            <h1 class="trip-view__full-info-title"
                v-if="!$mobile">{{trip.title}}</h1>
            <Row class="trip-view__badge -m-h-5"
                 wrap>
              <Badge v-for="badge in $trip.tags"
                     :key="`badge-trip-view-${badge.id}`"
                     :params="{text: badge.title, colors: {text: '#ffffff', background: badge.color}}"/>
            </Row>
            <Column>
              <Row align="center"
                   class="trip-view__rating-row">
                <Rating class="trip__rating"
                        :params="{id: $trip.id,
                        type: 'trips',
                        rating: $trip.rating,
                        currentUserRating: $trip.currentUserRating}"/>
              </Row>
              <span class="rating-count">{{$trip.countRating}} {{plural($trip.countRating,
                [`${$t('places_view.tourists.p_1')}`,
                `${$t('places_view.tourists.p_2')}`, `${$t('places_view.tourists.p_3')}`])}}
                {{ $t('places_view.this_obj') }}</span>
            </Column>
          </Column>
        </Row>
        <div class="place-view__play-button"
             v-if="checkAudio && $laptop"
             v-on:click="openPopupAudios">
          <Icon class="place-view__play-button-icon"
                xlink="play"
                viewport="0 0 20 20"/>
        </div>
        <Row v-if="$mobile"
             justify="end"
             full>
          <div class="place-view__play-button"
               v-if="checkAudio"
               v-on:click="openPopupAudios">
            <Icon class="place-view__play-button-icon"
                  xlink="play"
                  viewport="0 0 20 20"/>
          </div>
        </Row>
      </Section>
      <Section limiter>
        <Row :full="$mobile"
             :justify="$laptop ? 'between' : ''"
             :wrap="!$laptop">
          <Column class="grid-md-3 grid-lg-3"
                  :full="$mobile">
            <h2 class="trip-view__visit-title">{{ $t('trips_view.visit') }}</h2>
          </Column>
          <Column :full="$mobile"
                  class="grid-md-8 grid-lg-8 html-insert"
                  v-html="$trip.description"/>
        </Row>
      </Section>
      <Section v-if="showIfHavePlaces"
               class="trip-view__on-map"
               :limiter="!$mobile">
        <div class="trip-view__wrapper"
             :style="{width:'100%', height:'400px'}">
          <OnMap :params="paramsWithCoords"
                 :places="places"
                 :useNumber="true"/>
        </div>
      </Section>
      <Section v-if="showIfHavePlaces"
               class="trip-view__trip-plan"
               :limiter="!$mobile">
        <Row justify="between"
             :wrap="!$laptop">
          <Column class="grid-sm-full grid-md-8 grid-lg-3"
                  :full="$mobile">
            <h2 class="trip-view__trip-plan-title">{{ $t('trips_view.route') }}</h2>
          </Column>
          <Column class="grid-md-8 grid-lg-8"
                  :full="$mobile">
            <Row :key="point.id"
                 class="trip-view__trip-plan-row"
                 justify="between"
                 v-for="(point, index) in $trip.places">
              <div v-if="$laptop"
                   class="grid-md-1 grid-lg-1"/>
              <TripPlanPoint class="grid-sm-full grid-md-8 grid-lg-7"
                             :params="assign(point, index)"/>
            </Row>
          </Column>
        </Row>
      </Section>
      <Section v-if="agency"
               limiter>
        <AboutAgency :params="agency"/>
      </Section>
      <Section limiter>
        <Divider/>
      </Section>
      <Section limiter>
        <GuestReviews :params="{title: `${$t('comments.reviews')}`,
        button: `${$t('comments.leave_rev')}`}"
                      typePage="trips"/>
      </Section>
      <Section>
        <ThematicSlider :params="{array: popularRoutes,
                                  component: 'TripCard',
                                  count}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('trips_view.popular') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
                 class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                           :to="`${$locale.base}/trips`">{{$laptop ? $t('home.watch_all.p_1') : ''}} {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                    viewport="0 0 20 20"
                    :rotate="90"
                    xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TripView',
    data() {
      return {
        cityId: {
          default: null,
        },
        popularRoutes: [],
        parentValue: 'hello',
        params: {
          zoom: 11,
          sizeMap: {
            width: '100%',
            height: '400px',
          },
        },
      };
    },
    created() {
      this.$store.dispatch('GET_TRIP_FROM_SERVER', this.$route.params.id);
      this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
      this.$http.get(`/api/trips?count=100500&city_id=${this.$city.id}&lang=${this.$i18n.locale}`)
        .then(resolve => this.popularRoutes = resolve.data.data);
    },
    computed: {
      agency() {
        return this?.$trip?.agency;
      },
      height() {
        let height;
        if (this.$laptop) height = 450;
        if (this.$tablet) height = 405;
        if (this.$mobile) height = 180;
        return height;
      },
      count() {
        let count;
        if (this.$laptop) count = 3;
        if (this.$tablet) count = 2;
        if (this.$mobile) count = 1;
        return count;
      },
      indentation() {
        let indentation;
        if (this.$laptop) indentation = 30;
        if (this.$tablet) indentation = 8;
        if (this.$mobile) indentation = 8;
        return indentation;
      },
      subtype() {
        const type = this.$tripSubTypes.find(subtype => subtype.id === this.$trip.subtype_id);
        return type ? type.name : '';
      },
      imageSubtype() {
        const type = this.$tripSubTypes.find(subtype => subtype.id === this.$trip.subtype_id);
        return type ? type.image : '';
      },
      checkAudio() {
        return this?.$trip?.places?.find(item => item?.audios?.length > 0);
      },
      paramsWithCoords() {
        const newParams = this.params;
        newParams.coords = this.places.length > 0 ? this.places[0].location : [];
        return newParams;
      },
      distance() {
        return this?.$trip?.distance;
      },
      duration() {
        return this?.$trip?.duration;
      },
      level() {
        switch (this?.$trip?.level) {
          case 1:
            return this.$i18n.t('trips_view.levels.easy');
          case 2:
            return this.$i18n.t('trips_view.levels.medium');
          case 3:
            return this.$i18n.t('trips_view.levels.hard');
          default:
            return this.$i18n.t('trips_view.levels.no_lvl');
        }
      },
      trip() {
        return this.$trip;
      },
      images() {
        return this?.$trip?.images;
      },
      places() {
        return this?.$trip?.places;
      },
      showIfHavePlaces() {
        return this?.$trip?.places && this?.$trip?.places?.length > 0;
      },
      navigation() {
        return [{
          href: '/',
          title: this.$i18n.t('menu.main'),
        }, {
          href: '/trips',
          title: this.$i18n.t('menu.routes_and_t'),
        }, {
          href: this.$route.path,
          title: this.$trip.title,
        }];
      },
    },
    methods: {
      plural(n, titles) {
        // eslint-disable-next-line no-nested-ternary,max-len
        return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
      },
      updateCountRating() {
        this.$store.dispatch('GET_TRIP_FROM_SERVER', this.$route.params.id);
      },
      assign(point, index) {
        return Object.assign(point, { index: index + 1 });
      },
      openPopupAudios() {
        this.$store.commit('OPEN_POPUP', {
          clear: true,
          width: 800,
          height: '100vh',
          horizontal: 'right',
          vertical: 'center',
          type: 'PopupAudioTrips',
        });
      },
    },
    watch: {
      $route(newState) {
        this.$store.dispatch('GET_TRIP_FROM_SERVER', newState.params.id);
      },
    },
  };
</script>
